import {isLoggedIn} from "./guards";

export default [
  {
    path: '/',
    component: () => import('../layout/default/Default'),
    beforeEnter: isLoggedIn,
    children: [
      {
        path: '',
        component: () => import('../layout/default/Absolute.vue'),
        children: [
          {
            path: 'race/runs',
            name: 'race.runs',
            component: () => import('../applications/Runs/RaceRuns.vue'),
          },
          {
            path: 'runs',
            name: 'runs',
            component: () => import('../applications/Runs/Runs.vue'),
          },
        ],
      },
      {
        path: 'runs/:id',
        component: () => import('../layout/default/Absolute.vue'),
        beforeEnter: isLoggedIn,
        props: {
          refreshKeys: [
            'id',
          ],
          subNavInfo: "Run",
          subNav: [
            {to: {name: "run.detail"}, name: "Detail"},
            {to: {name: "run.track"}, name: "Track"},
            {to: {name: "run.iot"}, name: "IOT"},
            {to: {name: "run.stats"}, name: "Stats"},
            {to: {name: "run.modifications"}, name: "Modifications"},
          ],
        },
        children: [
          {
            path: '',
            name: 'run',
            props: true,
            redirect: { name: 'run.detail' },
            component: () => import('../applications/Runs/Run.vue'),
            children: [
              {
                path: 'detail',
                name: 'run.detail',
                props: true,
                component: () => import('../applications/Runs/Run/Detail.vue'),
              },
              {
                path: 'track',
                name: 'run.track',
                component: () => import('../applications/Runs/Run/Track.vue'),
              },
              {
                path: 'iot',
                name: 'run.iot',
                component: () => import('../applications/Runs/Run/IOT.vue'),
              },
              {
                path: 'stats',
                name: 'run.stats',
                component: () => import('../applications/Runs/Run/Stats.vue'),
              },
              {
                path: 'modifications',
                name: 'run.modifications',
                component: () => import('../applications/Runs/Run/Modifications.vue'),
              },
              {
                path: 'ax',
                name: 'run.ax',
                component: () => import('../applications/Runs/Run/AX.vue'),
              },
              {
                path: 's3',
                name: 'run.s3',
                component: () => import('../applications/Runs/Run/S3File.vue'),
              },
            ],
          },
        ],
      },
    ],
  },
]
