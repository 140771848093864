import { isLoggedIn } from "./guards";

export default [
  {
    path: "/",
    component: () => import("../layout/default/Default"),
    beforeEnter: isLoggedIn,
    children: [
      {
        path: "",
        component: () => import("../layout/default/Absolute.vue"),
        props: {
          subNav: [
            {
              to: { name: "users" },
              name: "Users",
              permission: [
                "wflwr.manage.route.users",
                "wflwr.models.user.list",
              ],
            },
            {
              to: { name: "users.with-groups" },
              name: "Users with Groups",
              permission: [
                "wflwr.manage.route.usersgroups",
                "wflwr.models.user.list",
              ],
            },
            {
              to: { name: "users.with-story" },
              name: "Users with Story",
              permission: [
                "wflwr.manage.route.users",
                "wflwr.models.user.list",
              ],
            },
          ],
        },
        children: [
          {
            path: "users",
            name: "users",
            component: () => import("../applications/User/Users.vue"),
          },
          {
            path: "users/with-groups",
            name: "users.with-groups",
            component: () => import("../applications/User/UsersWithGroups.vue"),
          },
          {
            path: "users/with-story",
            name: "users.with-story",
            component: () => import("../applications/User/UsersWithStory.vue"),
          },
        ],
      },
      {
        path: "users/:id",
        name: "users.user",
        component: () => import("../layout/default/Absolute.vue"),
        redirect: { name: "users.user.detail" },
        props: {
          subNavInfo: "User",
          subNav: [
            { to: { name: "users.user.detail" }, name: "Detail" },
            {
              to: { name: "users.user.groups" },
              name: "Groups",
              permission: ["wflwr.models.user.group.update"],
            },
          ],
        },
        children: [
          {
            path: "detail",
            name: "users.user.detail",
            props: true,
            component: () => import("../applications/User/User/Detail.vue"),
          },
          {
            path: "groups",
            name: "users.user.groups",
            props: true,
            component: () => import("../applications/User/User/Groups.vue"),
          },
        ],
      },
    ],
  },
];
