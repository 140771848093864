export function getFileDimensions(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (event) => {
            const blob = new Blob([event.target.result], { type: file.type })
            createImageBitmap(blob).then(bitmap => {
                resolve({ width: bitmap.width, height: bitmap.height })
                bitmap.close()
            }).catch(error => reject(error))
        }
        reader.onerror = (error) => reject(error)
        reader.readAsArrayBuffer(file)
    })
}

export function downloadBlob(blob, fileName) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
        return;
    }
    const linkElement = document.createElement('a');
    linkElement.href = window.URL.createObjectURL(blob);
    linkElement.setAttribute('download', fileName);
    document.body.appendChild(linkElement);
    linkElement.click();
    document.body.removeChild(linkElement);
}