export class EventBus {

    listeners = new Map()

    on(prefix, name, event) {
        this.listeners.set(`${prefix}:${name}`, event)
    }

    off(prefix, name) {
        this.listeners.delete(`${prefix}:${name}`)
    }

    emit(prefix, name, data) {
        for (const key of this.listeners.keys()) {
            if (key !== `${prefix}:${name}`)
                continue

            this.listeners.get(key)(data)
        }
    }

    emitAll(names, data) {
        names.forEach(name => {
            const split = name.split(':')
            this.emit(split[0], split[1], data)
        })
    }

    reset() {
        this.listeners.clear()
    }

    listListeners() {
        return Object.entries(this.listeners)
    }

    listKeyListeners() {
        return Object.keys(this.listeners)
    }

    listValueListeners() {
        return Object.values(this.listeners)
    }

}

const eventBus = new EventBus()
export default eventBus