import ConfirmModal from "@/components/Modals/ConfirmModal.vue";
import { createApp } from "vue";
import SettingsModal from "@/components/Modals/Settings/SettingsModal.vue";
import store from "@/store";
import router from "@/router";
import {
  createInput,
  defaultConfig as formkitDefaultConfig,
  plugin as formkitPlugin,
} from "@formkit/vue";
import { createProPlugin, inputs } from "@formkit/pro";
import { genesisIcons } from "@formkit/icons";
import { generateClasses } from "@formkit/themes";
import wflwrFormkitTheme from "@/assets/css/wflwr-formkit-theme";
import LabelSelector from "@/components/labelSelector/LabelSelector.vue";
import SearchSelect from "@/components/Select/SearchSelect.vue";
import date2 from "@/components/inputs/formkit/Date2.vue";
import Notification from "@/components/notification";
import CreateParticipantModal from "@/components/Modals/Participant/CreateParticipantModal.vue";
import CancelParticipantModal from "@/components/Modals/Participant/CancelParticipantModal.vue";
import RefundChargebackRegistrationModal from "@/components/Modals/Registration/RefundChargebackRegistrationModal.vue";
import RefundChargebackVoucherOrderModal from "@/components/Modals/VoucherOrder/RefundChargebackVoucherOrderModal.vue";
import RefundChargebackDonationModal from "@/components/Modals/Donation/RefundChargebackDonationModal.vue";
import Permissions from '@/permissions'
import Gate from '@/permissions/Gate'

export function confirm(title, description, callback) {
  createConfirmModalWithAccent(title, description, callback, "default");
}

export function confirmDanger(title, description, callback) {
    createConfirmModalWithAccent(title,description,callback,"danger")
}

export function confirmWarning(title, description, callback) {
    createConfirmModalWithAccent(title,description,callback,"warning")
}

export function settingsModal() {
    const modalElement = document.createElement('div')
    let instance = createApp(SettingsModal, {
            close() {
                instance.unmount();
                modalElement.remove();
            },
        }
    )

    const gate = new Gate({
      permissionPrefix: '',
      store
    })
    instance.use(store)
    instance.use(router)
    instance.use(Permissions, {gate} )
    document.body.appendChild(modalElement);
    instance.mount(modalElement)
}

export function createCancelParticipantModal(id) {
  createModal(CancelParticipantModal, {id: id})
}

export function createCancelRegistrationModal(id, props) {
  createModal(RefundChargebackRegistrationModal, {id: id, ...props})
}

export function createCancelVoucherOrderModal(id, showCancelPayment=true) {
  createModal(RefundChargebackVoucherOrderModal, {id: id, showCancelPayment: showCancelPayment})
}

export function createCancelDonationModal(id, showCancelPayment= true) {
  createModal(RefundChargebackDonationModal, {id: id, showCancelPayment: showCancelPayment})
}

export function createCreateParticipantModal() {
  createModal(CreateParticipantModal)
}

export function createModal(modal, props) {
  const formkitProPlugin = createProPlugin(process.env.VUE_APP_FORMKIT_PRO_KEY, inputs)
  const modalElement = document.createElement('div')
  let instance = createApp(modal, {
      ...props,
      close() {
        instance.unmount();
        modalElement.remove();
      },
    }
  )
  instance.use(store)
  instance.use(router)
  instance.use(formkitPlugin, formkitDefaultConfig({
      config: {
          classes: generateClasses(wflwrFormkitTheme),
      },
      icons: {
          ...genesisIcons,
      },
      plugins: [formkitProPlugin],
      inputs: {
          selectLabel: createInput(LabelSelector, {
              props: ['disableManage'],
          }),
          searchSelect: createInput(SearchSelect, {
              props: ['options','value','allowEmpty','placeholder','selectFirstIfOnlyOption'],
          }),
          date2: createInput(date2, {
              props: ['value']
          }),
      }
  }))
  instance.config.globalProperties.$notification = Notification
  document.body.appendChild(modalElement);
  instance.mount(modalElement)
}

function createConfirmModalWithAccent(title, description, callback, accent){
    const modalElement = document.createElement('div')
    let instance = createApp(ConfirmModal, {
            title,
            description,
            callback,
            accent,
            close() {
                instance.unmount();
                modalElement.remove();
            },
        }
    )
    document.body.appendChild(modalElement);
    instance.mount(modalElement)
}